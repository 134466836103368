<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Purchase Order Detail">
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <b-button
              v-if="purchaseOrderIsConfirmed === true || purchaseOrderIsConfirmedByPresdir === true"
              type="button"
              variant="primary"
              class="mr-1 float-rigth"
              @click="print()"
            >
              <feather-icon icon="PrinterIcon" size="16" class="align-middle" />
              Print
            </b-button>
            <div class="devider" />
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Number "
              label-for="number"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_number"
                v-model="number"
                placeholder="Supplier"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Supplier "
              label-for="supplier"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_supplier"
                v-model="supplier"
                placeholder="Supplier"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Shipment By "
              label-for="purchase_order_shipment_by"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_shipment_by"
                v-model="shipmentBy"
                placeholder="Shipment By"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Purchase Order Type "
              label-for="purchase_order_type"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_type"
                v-model="type"
                placeholder="Purchase Order Type"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Delivery Address "
              label-for="purchase_order_delivery_address"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_delivery_address"
                v-model="deliveryAddress"
                placeholder="Delivery Address"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Payment Type "
              label-for="payment_type"
              label-cols-md="12"
            >
              <b-form-input
                id="payment_type"
                v-model="paymentType"
                placeholder="Payment Type"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Delivery Schedule "
              label-for="purchase_order_delivery_schedule"
              label-cols-md="12"
              readonly
            >
              <b-form-input
                id="purchase_order_delivery_schedule"
                v-model="purchaseOrderSchedule"
                placeholder="Delivery Schedule"
                required="required"
                readonly
              />
            </b-form-group>

            <b-form-group
              label="Is With PPN "
              label-for="purchase_order_is_with_tax"
              label-cols-md="12"
              readonly
            >
              <b-form-input
                id="purchase_order_is_with_tax"
                v-model="purchaseOrderWithTax"
                readonly
              />
            </b-form-group>

            <b-form-group
              label="Billing Address "
              label-for="billing_address"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_delivery_address"
                v-model="billingAddress"
                placeholder="Delivery Address"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Term Of Payment "
              label-for="term_of_payment"
              label-cols-md="12"
            >
              <b-form-input
                id="term_of_payment"
                v-model="termOfPayment"
                placeholder="Term Of Payment"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Discount Percentage (%)"
              label-for="purchase_order_discount_percentage"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_discount_percentage"
                v-model="purchaseOrderDiscountPercentage"
                placeholder="Discount Percentage"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Transport Fee"
              label-for="purchase_order_transport_fee"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_transport_fee"
                v-model="transportFee"
                placeholder="Transport Fee"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Link"
              label-for="purchase_order_link"
              label-cols-md="12"
            >
              <b-form-input
                id="purchase_order_link"
                v-model="purchaseOrderLink"
                placeholder="Link"
                readonly
              />
            </b-form-group>
            <b-form-group>
              <label for="no_invoice">Items :</label>
            </b-form-group>
            <b-form-group>
              <br />
              <div v-if="purchaseOrderByPurchasingIsConfirmed === false">
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Unit name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Price
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Requested QTY
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                    Subtotal
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.purchase_order_detail_item_id"
                >
                  <br />
                  <b-col cols="3" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>

                  <b-col cols="2" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{
                          item.item.unit
                            ? capitalizeWords(item.item.unit.unit_name)
                            : "-"
                        }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="2" class="text-center form-control">
                    {{
                      item.item_reference != null
                        ? item.item_reference.currency.currency_symbol
                        : currency
                    }}{{ numFormat(item.purchase_order_detail_item_price) }}
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-form-input
                      v-model="item.purchase_order_detail_item_qty"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="3" class="text-center">
                    <span class="form-control">{{ currency }}{{ numFormat(roundToTwoDecimals((item.purchase_order_detail_item_price - ((purchaseOrderDiscountPercentage/100)*(item.purchase_order_detail_item_price))) * item.purchase_order_detail_item_qty)) }}</span>
                  </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    TOTAL CHARGE
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(totalCharge)) }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    VAT({{ isWithTax==true?taxPercentageReal:0 }}%)
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(totalTax)) }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    GAND TOTAL
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(grandTotal)) }}
                  </b-col>
                </b-row>
              </div>
              <div
                v-if="
                  purchaseOrderByPurchasingIsConfirmed === true &&
                    purchaseOrderByWarehouseIsConfirmed == false
                "
              >
                <b-row>
                  <b-col cols="4" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Unit name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Chosen Price
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Sent QTY
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Received
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.purchase_order_detail_item_id"
                >
                  <br />
                  <b-col cols="4" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="2" class="text-center"
                    ><b-list-group-item href="#">
                      {{
                        item.item.unit
                          ? capitalizeWords(item.item.unit.unit_name)
                          : "-"
                      }}
                    </b-list-group-item>
                  </b-col>
                  <b-col cols="2" class="text-center form-control">
                    {{
                      item.item_reference != null
                        ? item.item_reference.currency.currency_symbol
                        : currency
                    }}
                    {{ numberFormat(item.purchase_order_detail_item_price) }}
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-form-input
                      v-model="item.purchase_order_detail_item_qty"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    TOTAL CHARGE
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(totalCharge)) }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    VAT({{ isWithTax==true?taxPercentageReal:0 }}%)
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(totalTax)) }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    GAND TOTAL
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(grandTotal)) }}
                  </b-col>
                </b-row>
              </div>
              <div
                v-if="
                  purchaseOrderByPurchasingIsConfirmed === true &&
                    purchaseOrderByWarehouseIsConfirmed == true
                "
              >
                <b-row>
                  <b-col cols="6" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Unit Name
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Chosen Price
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    Sent QTY
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.purchase_order_detail_item_id"
                >
                  <br />
                  <b-col cols="6" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-list-group-item href="#">
                      {{
                        item.item.unit
                          ? capitalizeWords(item.item.unit.unit_name)
                          : "-"
                      }}
                    </b-list-group-item>
                  </b-col>
                  <b-col cols="2" class="text-center form-control">
                    {{
                      item.item_reference != null
                        ? item.item_reference.currency.currency_symbol
                        : currency
                    }}
                    {{ numberFormat(item.purchase_order_detail_item_price) }}
                  </b-col>
                  <b-col cols="2" class="text-center">
                    <b-form-input
                      v-model="item.purchase_order_detail_item_qty"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    TOTAL CHARGE
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(totalCharge)) }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    VAT({{ isWithTax==true?taxPercentageReal:0 }}%)
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(totalTax)) }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                  </b-col>
                  <b-col cols="2" class="text-center font-weight-bold">
                    GRAND TOTAL
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                   {{ currency }}{{ numFormat(roundToTwoDecimals(grandTotal)) }}
                  </b-col>
                </b-row>
              </div>
            </b-form-group>
            <b-form-group>
              <label for="no_invoice">Status :</label>
              <vue-horizontal-timeline :items="statusItems" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from "@vue/composition-api"

const VersionCheck = ""
const items = []
const chosenItem = ref(3)
const chosens = []
const statusItems = []
const supplier = ""
const shipmenBy = ""
const deliveryAddress = ""
const purchaseOrderSchedule = ""
const billingAddress = ""
const termOfPayment = ""
const paymentType = ""
const number = ""
const type = ""
const purchaseOrderIsConfirmed = false
const purchaseOrderByPurchasingIsConfirmed = false
const purchaseOrderByWarehouseIsConfirmed = false
const purchaseOrderIsConfirmedByPresdir = false
const purchaseOrderLink = ""
const purchaseOrderDiscountPercentage = ""
const purchaseOrderWithTax = ""
const totalCharge = 0
const currency = ''
const isWithTax = false
const taxPercentage = 0
const taxPercentageReal = 0
const transportFee = 0
const totalTax = 0
const grandTotal = 0
export default {
  components: {
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      purchaseOrderIsConfirmedByPresdir,
      transportFee,
      taxPercentageReal,
      grandTotal,
      isWithTax,
      taxPercentage,
      totalTax,
      currency,
      totalCharge,
      purchaseOrderWithTax,
      number,
      purchaseOrderIsConfirmed,
      type,
      purchaseOrderLink,
      purchaseOrderDiscountPercentage,
      purchaseOrderByPurchasingIsConfirmed,
      purchaseOrderByWarehouseIsConfirmed,
      paymentType,
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      supplier,
      shipmenBy,
      deliveryAddress,
      purchaseOrderSchedule,
      billingAddress,
      termOfPayment,
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
    const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_SETTING}tax`
    axios
      .get(url, { headers })
      .then(responseSet => {
      const item = JSON.parse(localStorage.getItem("podetail"))
      this.supplier = item.contact ? `${item.contact.contact_name}  (${item.contact.contact_city})` : ''
      this.shipmentBy = item.purchase_order_shipment_by
      this.paymentType = item.purchase_order_payment_type
      this.deliveryAddress = item.purchase_order_delivery_address
      this.number = item.purchase_order_number
      this.purchaseOrderByPurchasingIsConfirmed = item.purchase_order_is_received_by_purchasing !== null ? true : false
      this.purchaseOrderByWarehouseIsConfirmed = item.purchase_order_is_received_by_warehouse !== null ? true : false
      this.purchaseOrderSchedule = this.dateSimple(item.purchase_order_delivery_schedule)
      this.purchaseOrderDiscountPercentage = item.purchase_order_discount_percentage
      this.transportFee = item.purchase_order_transport_fee !== null ? item.purchase_order_transport_fee : 0
      this.purchaseOrderLink = item.purchase_order_link
      this.billingAddress = `${item.branch ? item.branch.branch_code : '-'} (${item.branch ? item.branch.branch_city : '-'})`
      this.termOfPayment = item.purchase_order_term_of_payment
      this.purchaseOrderWithTax = item.purchase_order_is_with_tax === true ? "Yes" : "No"
      this.isWithTax = item.purchase_order_is_with_tax
      this.type = item.purchase_order_is_center === true ? "Head Office" : "Lokal"
      this.taxPercentageReal = responseSet.data.data
      this.purchaseOrderIsConfirmedByPresdir = item.purchase_order_is_confirmed_by_president_director !== null ? true : false
      this.taxPercentage = parseFloat(responseSet.data.data) / 100
      this.purchaseOrderIsConfirmed = item.purchase_order_is_confirmed
      this.currency = item.currency !== null ? item.currency.currency_symbol : 'Rp'
      const id = item.purchase_order_id
      const obj1 = {
        title: "Created",
        content: `Purchase Order Has been Created By ${
          item.user.user_fullname
        } at ${this.dateSimple(
          item.purchase_order_created_time,
          "YYYY-MM-DD HH:mm:ss",
        )}`,
        stepCssClass: "has-step-green",
        boxCssClass: "has-color-red",
      }
      const obj2 = {
        title: "Laboratory Manager",
        content:
          item.purchase_order_is_confirmed === true
            ? `Purchase Order Has been Accepted by ${
                item.lab_manager.user_fullname
              } at ${this.dateSimple(
                item.purchase_order_is_confirmed_date,
                "YYYY-MM-DD HH:mm:ss",
              )}`
            : `Purchase Order Has not been Accepted By Laboratory Manager`,
        stepCssClass:
          item.purchase_order_is_confirmed === true
            ? "has-step-green"
            : "has-step-red",
      }
      const obj3 = {
        title: "Received Purchasing",
        content:
          item.purchase_order_is_received_by_purchasing === true
            ? `Purchase Order Has been Received By Purchasing at ${this.dateSimple(
                item.purchase_order_is_received_by_purchasing_time,
                "YYYY-MM-DD HH:mm:ss",
              )}`
            : `Purchase Order Has not been Received by Purchasing Lokal`,
        stepCssClass:
          item.purchase_order_is_received_by_purchasing === true
            ? "has-step-green"
            : "has-step-red",
      }
      const obj4 = {
        title: "Received Warehouse",
        content:
          item.purchase_order_is_received_by_warehouse === true
            ? `Purchase Order Has been Received By Warehouse at ${this.dateSimple(
                item.purchase_order_is_received_by_warehouse_time,
                "YYYY-MM-DD HH:mm:ss",
              )}`
            : `Purchase Order Has not been Received by Warehouse`,
        stepCssClass:
          item.purchase_order_is_received_by_warehouse === true
            ? "has-step-green"
            : "has-step-red",
      }
      this.statusItems = []
      this.statusItems.push(obj1)
      this.statusItems.push(obj2)
      this.statusItems.push(obj3)
      this.statusItems.push(obj4)
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_DETAILS}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            response.data.data.map(d => {
              this.totalCharge += d.purchase_order_detail_item_price * d.purchase_order_detail_item_qty
              this.totalTax = this.isWithTax === true ? this.totalCharge * this.taxPercentage : 0
            })

            this.grandTotal = parseFloat(this.totalCharge) + parseFloat(this.totalTax) + parseFloat(this.transportFee)
            this.items = response.data.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
      })
    },
    print() {
      const userToken = this.$cookies.get("userToken")
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      }
      const item = JSON.parse(localStorage.getItem("podetail"))
      const id = item.purchase_order_id
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_PURCHASE_ORDER}${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
            window.open(url, "_blank")
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Data failed",
                icon: "CheckIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem(val) {
      if (this.chosens.length > 0) {
        let same = false
        this.chosens.map(elem => {
          if (elem.value === val.value) {
            same = true
          }
        })
        if (same === false) {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = { label: elem.label, value: elem.value, qty: 1 }
            }
          })
          this.chosens.push(item)
        }
      } else {
        let item = null
        this.items.map(elem => {
          if (elem.value === val.value) {
            item = { label: elem.label, value: elem.value, qty: 1 }
          }
        })

        this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.value !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    previous() {
      this.$router.go(-1)
    },
    numberFormat(value) {
      return parseFloat(value).toFixed(2)
    },
    capitalizeWords(str) {
      // Split the string into words using spaces as the separator
      const words = str.split(" ")
      // Capitalize the first letter of each word and join them back together
      const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      // Join the capitalized words into a single string with spaces
      return capitalizedWords.join(" ")
    },
    numFormat(num) {
    // Convert the number to a string
    const numberString = String(num)
    // Split the string into integer and fractional parts
    const [integerPart, fractionalPart = ''] = numberString.split('.')
    // Add comma separators to the integer part
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    // Return the formatted number with the fractional part
    return fractionalPart ? `${integerWithCommas}.${fractionalPart}` : integerWithCommas
},
 roundToTwoDecimals(num) {
    return (Math.round(num * 100) / 100).toFixed(2)
},
  },
}
</script>
